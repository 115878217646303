import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../../../utils/axios';
import { useDispatch } from '../../../redux/store';
import { getUsers } from '../../../redux/slices/users';

//
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  response: null,
  loading: false,
};

const terrys = [
  {
    id: '33',
    day: '2023-05-5T00:00:00.000Z',
    event_type: 1,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 20,
    totalNumberOfOperation: 370,
  },
  {
    id: '34',
    day: '2023-05-6T00:00:00.000Z',
    event_type: 1,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 15,
    totalNumberOfOperation: 370,
  },
  {
    id: '35',
    day: '2023-05-7T00:00:00.000Z',
    event_type: 1,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 41,
    totalNumberOfOperation: 370,
  },
  {
    id: '36',
    day: '2023-05-8T00:00:00.000Z',
    event_type: 1,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 11,
    totalNumberOfOperation: 370,
  },
  {
    id: '37',
    day: '2023-05-9T00:00:00.000Z',
    event_type: 1,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 32,
    totalNumberOfOperation: 370,
  },
  {
    id: '38',
    day: '2023-05-10T00:00:00.000Z',
    event_type: 1,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 41,
    totalNumberOfOperation: 370,
  },
  {
    id: '39',
    day: '2023-05-5T00:00:00.000Z',
    event_type: 2,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 38,
    totalNumberOfOperation: 370,
  },
  {
    id: '40',
    day: '2023-05-6T00:00:00.000Z',
    event_type: 2,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 27,
    totalNumberOfOperation: 370,
  },
  {
    id: '41',
    day: '2023-05-7T00:00:00.000Z',
    event_type: 2,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 32,
    totalNumberOfOperation: 370,
  },
  {
    id: '42',
    day: '2023-05-8T00:00:00.000Z',
    event_type: 2,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 19,
    totalNumberOfOperation: 370,
  },
  {
    id: '43',
    day: '2023-05-9T00:00:00.000Z',
    event_type: 2,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 3,
    totalNumberOfOperation: 370,
  },
  {
    id: '44',
    day: '2023-05-10T00:00:00.000Z',
    event_type: 2,
    totalNumberOfAdsClick: 41,
    totalNumberOfOperationWithAds: 9,
    totalNumberOfOperation: 370,
  },
];

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      response: null,
    };
  }
  if (action.type === 'GET_REPORTS') {
    return {
      response: {
        ...state.response,
        reports: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      },
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const ReportsContext = createContext(null);

// ----------------------------------------------------------------------

ReportsProvider.propTypes = {
  children: PropTypes.node,
};

export function ReportsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toolkitDispatch = useDispatch();

  const initialize = useCallback(async () => {
    try {
      // TODO
      dispatch({
        type: 'INITIAL',
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Get Reports
  const getReports = useCallback(async ({ from, to, project_domain }) => {
    dispatch({
      type: 'GET_REPORTS',
      payload: {
        response: null,
        loading: true,
      },
    });
    try {
      const response = await axios.get('/reports/r01-convertion-rate', {
        params: { from, to, project_domain },
      });

      dispatch({
        type: 'GET_REPORTS',
        payload: {
          //   response: response.data,
          response: terrys,
          loading: false,
        },
      });
    } catch (error) {
      dispatch({
        type: 'GET_REPORTS',
        payload: {
          response: null,
          loading: false,
        },
      });
      throw new Error(error.message);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const memoizedValue = useMemo(
    () => ({
      response: state.response,
      loading: state.loading,
      getReports,
    }),
    [state.response, state.loading, getReports]
  );

  return <ReportsContext.Provider value={memoizedValue}>{children}</ReportsContext.Provider>;
}
