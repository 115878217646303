import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

export const getUsers = createAsyncThunk('getUsers', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get('/users');
    const data = await response.data;
    return data;
  } catch (err) {
    // You can choose to use the message attached to err or write a custom error
    return rejectWithValue('Opps there seems to be an error, /users');
  }
});

const initialState = {
  isLoading: false,
  error: null,
  users: [],
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: (state) => {
      state.users = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
      state.isLoading = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.users = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export default slice.reducer;
export const { resetUsers } = slice.actions;
