import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_PAGE } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

AdminGuardNavSection.propTypes = {
  children: PropTypes.node,
};

export default function AdminGuardNavSection({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();

  if (user.role !== 'admin' && children.props.children === 'administration') {
    return null;
  }
  if (user.role !== 'admin' && children.props.children === 'marketing') {
    return null;
  }

  return <> {children} </>;
}
