import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../../../utils/axios';
import { useDispatch } from '../../../redux/store';
import { getUsers } from '../../../redux/slices/users';

//
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  response: null,
  loading: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      response: null,
    };
  }
  if (action.type === 'GET_LEADS') {
    return {
      response: {
        ...state.response,
        leads: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      },
    };
  }
  if (action.type === 'GET_LEAD_BY_EMAIL') {
    return {
      response: {
        ...state.response,
        leadByEmail: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      },
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const LeadsContext = createContext(null);

// ----------------------------------------------------------------------

LeadsProvider.propTypes = {
  children: PropTypes.node,
};

export function LeadsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toolkitDispatch = useDispatch();

  const initialize = useCallback(async () => {
    try {
      // TODO
      dispatch({
        type: 'INITIAL',
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Get Leads
  const getLeads = useCallback(async ({ order, page, take }) => {
    clearLeads();
    clearLeadByEmail();
    try {
      const response = await axios.get('/leads', { params: { order, page, take } });

      dispatch({
        type: 'GET_LEADS',
        payload: {
          response: response.data,
          loading: false,
        },
      });
    } catch (error) {
      clearLeads();
      throw new Error(error.message[0]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Get Lead By Email
  const getLeadByEmail = useCallback(async (email) => {
    clearLeads();
    clearLeadByEmail();
    try {
      const response = await axios.get(`/leads/email/${email}`);

      dispatch({
        type: 'GET_LEAD_BY_EMAIL',
        payload: {
          response: response.data,
          loading: false,
        },
      });
    } catch (error) {
      clearLeadByEmail();
      throw new Error(error.message);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clearLeadByEmail = useCallback(async () => {
    dispatch({
      type: 'GET_LEAD_BY_EMAIL',
      payload: {
        response: null,
        loading: false,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clearLeads = useCallback(async () => {
    dispatch({
      type: 'GET_LEADS',
      payload: {
        response: null,
        loading: false,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const memoizedValue = useMemo(
    () => ({
      response: state.response,
      loading: state.loading,
      getLeads,
      getLeadByEmail,
    }),
    [state.response, state.loading, getLeads, getLeadByEmail]
  );

  return <LeadsContext.Provider value={memoizedValue}>{children}</LeadsContext.Provider>;
}
