import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';
import { setSession } from '../auth/utils';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });
axiosInstance.interceptors.request.use(
  (request) => {
    if (request.url !== '/sign-in') {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      };
    }

    return request;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      if (error.config.url === '/authentication/refresh-tokens') {
        alert('Token Expired...');
        window.location.href = PATH_AUTH.login;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      } else {
        const localRefreshToken = localStorage.getItem('refreshToken');
        try {
          const response = await axiosInstance.post('/authentication/refresh-tokens', {
            refreshToken: localRefreshToken,
          });
          // eslint-disable-next-line no-shadow
          const { accessToken, refreshToken } = response.data;
          setSession(accessToken, refreshToken);
          return axiosInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error || 'Something went wrong');
        }
      }
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
