import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../../../utils/axios';
import { useDispatch } from '../../../redux/store';
import { getUsers } from '../../../redux/slices/users';

//
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  response: null,
  loading: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      response: null,
    };
  }
  if (action.type === 'ADD_USER') {
    return {
      response: {
        ...state.response,
        add: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      },
    };
  }
  if (action.type === 'DELETE_USER') {
    return {
      deleteResponse: action.payload.response,
      loading: action.payload.loading,
    };
  }
  if (action.type === 'GET_USER_BY_ID') {
    return {
      response: {
        ...state.response,
        detail: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      },
    };
  }
  if (action.type === 'EDIT_USER') {
    return {
      response: {
        ...state.response,
        edit: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      },
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const UserContext = createContext(null);

// ----------------------------------------------------------------------

UserProvider.propTypes = {
  children: PropTypes.node,
};

export function UserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toolkitDispatch = useDispatch();

  const initialize = useCallback(async () => {
    try {
      // TODO
      dispatch({
        type: 'INITIAL',
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Add User
  const addUser = useCallback(async (email, password) => {
    dispatch({
      type: 'ADD_USER',
      payload: {
        response: null,
        loading: true,
      },
    });
    try {
      const response = await axios.post('/users', {
        email,
        password,
      });
      const { data } = response.data;
      dispatch({
        type: 'ADD_USER',
        payload: {
          response: data,
          loading: false,
        },
      });

      toolkitDispatch(getUsers());
    } catch (error) {
      dispatch({
        type: 'ADD_USER',
        payload: {
          response: null,
          loading: false,
        },
      });
      throw new Error(error.message[0]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Edit User
  const editUser = useCallback(async (id, role, password) => {
    dispatch({
      type: 'EDIT_USER',
      payload: {
        response: null,
        loading: true,
      },
    });
    try {
      const response = await axios.patch(`/users/${id}`, {
        role,
        password,
      });
      const { data } = response.data;

      dispatch({
        type: 'EDIT_USER',
        payload: {
          response: data,
          loading: false,
        },
      });

      toolkitDispatch(getUsers());
    } catch (error) {
      dispatch({
        type: 'EDIT_USER',
        payload: {
          response: null,
          loading: false,
        },
      });
      throw new Error(error.message[0]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Delete User
  const deleteUser = useCallback(async (id) => {
    dispatch({
      type: 'DELETE_USER',
      payload: {
        response: null,
        loading: true,
      },
    });
    const response = await axios.delete(`/users/${id}`, {
      id,
    });
    const { data } = response.data;
    dispatch({
      type: 'DELETE_USER',
      payload: {
        response: data,
        loading: false,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Get User By Id
  const getUserById = useCallback(async (id) => {
    dispatch({
      type: 'GET_USER_BY_ID',
      payload: {
        response: null,
        loading: true,
      },
    });
    const response = await axios.get(`/users/${id}`, {
      id,
    });

    dispatch({
      type: 'GET_USER_BY_ID',
      payload: {
        response: response.data,
        loading: false,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const memoizedValue = useMemo(
    () => ({
      response: state.response,
      loading: state.loading,
      addUser,
      editUser,
      deleteUser,
      getUserById,
    }),
    [state.response, state.loading, addUser, editUser, deleteUser, getUserById]
  );

  return <UserContext.Provider value={memoizedValue}>{children}</UserContext.Provider>;
}
