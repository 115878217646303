import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../../../utils/axios';
import { useDispatch } from '../../../redux/store';
import { getUsers } from '../../../redux/slices/users';

//
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  response: null,
  loading: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      response: null,
    };
  }
  if (action.type === 'GET_LEAD_OPERATIONS') {
    return {
      response: {
        ...state.response,
        leadOperations: {
          data: action.payload.response,
          loading: action.payload.loading,
        },
      },
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const LeadOperationsContext = createContext(null);

// ----------------------------------------------------------------------

LeadOperationsProvider.propTypes = {
  children: PropTypes.node,
};

export function LeadOperationsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toolkitDispatch = useDispatch();

  const initialize = useCallback(async () => {
    try {
      // TODO
      dispatch({
        type: 'INITIAL',
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Get Lead Operations
  const getLeadOperations = useCallback(
    async ({ order, page, take, from, to, project_domain, email, event_type }) => {
      dispatch({
        type: 'GET_LEAD_OPERATIONS',
        payload: {
          response: null,
          loading: true,
        },
      });
      try {
        const response = await axios.get('/lead-operations', {
          params: { order, page, take, from, to, project_domain, email, event_type },
        });

        dispatch({
          type: 'GET_LEAD_OPERATIONS',
          payload: {
            response: response.data,
            loading: false,
          },
        });
      } catch (error) {
        dispatch({
          type: 'GET_LEAD_OPERATIONS',
          payload: {
            response: null,
            loading: false,
          },
        });
        throw new Error(error.message);
      }
    },
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const memoizedValue = useMemo(
    () => ({
      response: state.response,
      loading: state.loading,
      getLeadOperations,
    }),
    [state.response, state.loading, getLeadOperations]
  );

  return (
    <LeadOperationsContext.Provider value={memoizedValue}>
      {children}
    </LeadOperationsContext.Provider>
  );
}
